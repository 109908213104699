/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    em: "em",
    hr: "hr",
    div: "div",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In quantum computing, a ", React.createElement(_components.strong, null, "Toffoli gate"), " is a type of gate that acts on three qubits: two ", React.createElement(_components.strong, null, "control qubits"), ", which affect the state of a third, ", React.createElement(_components.strong, null, "target qubit"), ". Toffoli gates can be used to simulate ", React.createElement(_components.em, null, React.createElement(_components.strong, null, "any")), " classical logic circuit."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "The symbol for a Toffoli gate is similar to that of a CNOT gate, and is shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 77.84810126582278%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABJ0AAASdAHeZh94AAAA40lEQVQ4y8VT7QqEIBDsyXuDXqB38KH6WUGEmn1pe4ywnZV1xHWcMLi44zK7o8myLISF/QrzPF/muUbCwbeLayQcQMEwDNR13QqtNRljqG1byrKMiqKgcRz9eciZpumtMCyIhLXWxwznHPV9T3meU1VVK3fP+X3Ln0yRUm6UPGbKGTdqCs8Dc+QdeRghhKCmaTyX8yHnYAoMwfBxGW4zcI520zT1LrNJIQdC/m8K3uOjPwUFbynkAe8fLYBidV37XxHLbx526DKGrZQ6AD+kLEuPWB5G3moZCqAOHTxiyv7ilSkvNSDsZ+6YenoAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"toffoli-gate-symbol\"\n        title=\"toffoli-gate-symbol\"\n        src=\"/static/ad9c8f51a471ab8f5c86473726377501/f058b/toffoli-gate-symbol.png\"\n        srcset=\"/static/ad9c8f51a471ab8f5c86473726377501/c26ae/toffoli-gate-symbol.png 158w,\n/static/ad9c8f51a471ab8f5c86473726377501/6bdcf/toffoli-gate-symbol.png 315w,\n/static/ad9c8f51a471ab8f5c86473726377501/f058b/toffoli-gate-symbol.png 630w,\n/static/ad9c8f51a471ab8f5c86473726377501/0f2bc/toffoli-gate-symbol.png 742w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Because the Toffoli gate is like a CNOT gate with an extra control qubit (an extra ‘C’), it’s also occasionally referred to as the ", React.createElement(_components.strong, null, "CCNOT gate"), "."), "\n", React.createElement(_components.p, null, "Qubits $a$ and $b$ are the control qubits, and qubit $c$ is the target qubit. If both $a$ and $b$ are in the $|1\\rangle$ state, the state of $c$ is flipped. Otherwise, it is left alone. Of course, if qubit $a$ or $b$ is in a superposition state, the state of $c$ becomes entangled with $a$ or $b$."), "\n", React.createElement(_components.p, null, "The Toffoli gate is represented in matrix form as below."), "\n", React.createElement(_components.p, null, "$\\text{Toffoli} \\equiv \\left[ \\begin{matrix}1 & 0 & 0 & 0 & 0 & 0 & 0 & 0 \\\\ 0 & 1 & 0 & 0 & 0 & 0 & 0 & 0 \\\\ 0 & 0 & 1 & 0 & 0 & 0 & 0 & 0 \\\\ 0 & 0 & 0 & 1 & 0 & 0 & 0 & 0 \\\\ 0 & 0 & 0 & 0 & 1 & 0 & 0 & 0 \\\\ 0 & 0 & 0 & 0 & 0 & 1 & 0 & 0 \\\\ 0 & 0 & 0 & 0 & 0 & 0 & 0 & 1 \\\\ 0 & 0 & 0 & 0 & 0 & 0 & 1 & 0 \\end{matrix} \\right].$"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.Toffoli.html"
  }, "Toffoli gate in PennyLane"), " is available as ", React.createElement(_components.code, null, "qml.Toffoli"), ". This gate is called ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/qiskit.circuit.library.CCXGate"
  }, React.createElement(_components.code, null, "CCXGate"), " in Qiskit"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Fun fact"), ": The Toffoli gate is similar to a NAND gate. Since all of the other classical gates — such as AND, OR, NOT, XOR, and XNOR — can be created using a combination of NAND gates, the Toffoli gate can be used for universal classical computation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
